import { CommonModule, JsonPipe, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  Renderer2,
  effect,
  inject,
  input,
  viewChild,
} from '@angular/core';
import { Maybe } from '@designage/gql';
import { IDeviceInfo } from '@desquare/interfaces';
import { DeviceStatusInfo } from '@desquare/models';
import { getDeviceTime } from '@desquare/utils';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { getTimeZones } from '@vvo/tzdb';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgTemplateOutlet, TranslateModule, NgbTooltipModule, JsonPipe],
  selector: 'app-device-brightness',
  template: `
    @if(deviceInfo().screen !== null && getCurrentBrightness(); as
    currentBrightness){ @if(deviceInfo().screen?.isPoweredOn){
    @if(isNumber(currentBrightness.brightness)){
    <div #badge style="min-width: 4rem" class="px-2 fs-6 badge">
      @if(currentBrightness.schedule !== 'NO_SCHEDULE'){
      <i
        [ngClass]="
          currentBrightness.schedule === 'DEVICE_BRIGHTNESS_NIGHT'
            ? 'ri-moon-fill'
            : 'ri-sun-fill'
        "
        class="pe-1"
      ></i
      >}
      <span class="px-1">
        {{ (currentBrightness.brightness | translate) + '%' }}
      </span>
    </div>
    } @else{
    <ng-container *ngTemplateOutlet="noData"></ng-container>
    } }@else{
    <div style="min-width: 6rem" class="px-2 fs-6 badge bg-secondary">
      <i class="ri-shut-down-line pe-1"></i>
      <span class="px-1">
        {{ 'DISPLAY_POWER_SAVE' | translate }}
      </span>
    </div>
    } }@else{
    <ng-container *ngTemplateOutlet="noData"></ng-container>
    }
    <ng-template #noData>
      <div class="text-secondary">
        {{ 'NO_DATA' | translate }}
      </div>
    </ng-template>
  `,
})
export class DeviceBrightnessComponent {
  deviceInfo = input.required<IDeviceInfo>();
  renderer = inject(Renderer2);
  protected badge = viewChild<ElementRef>('badge');

  isNumber(val: any): boolean {
    return typeof val === 'number';
  }

  constructor() {
    effect(() => {
      const brightness = this.getCurrentBrightness().brightness;
      if (brightness != null && this.badge()?.nativeElement)
        this.renderer.setStyle(
          this.badge()?.nativeElement,
          'background',
          `linear-gradient(90deg, rgb(24 136 100) ${
            brightness - 1
          }%,  rgb(108 117 125) ${brightness}%)`
        );
    });
  }

  getCurrentBrightness(): {
    brightness: Maybe<number>;
    schedule: Maybe<
      'NO_SCHEDULE' | 'DEVICE_BRIGHTNESS_DAY' | 'DEVICE_BRIGHTNESS_NIGHT'
    >;
  } {
    const timezoneOffset = this.getTimezoneOffsetByName(
      this.deviceInfo().currentTime?.timezone
    );
    const brightness = this.deviceInfo().screen?.brightness;
    if (
      this.deviceInfo().screen?.brightness &&
      this.deviceInfo().currentTime?.currentDate &&
      timezoneOffset
    ) {
      const currentDeviceTimeString = getDeviceTime(
        this.deviceInfo().currentTime?.currentDate,
        timezoneOffset
      );
      if (brightness?.brightness1 === brightness?.brightness2)
        return {
          brightness: brightness?.brightness1,
          schedule: 'NO_SCHEDULE',
        };
      if (
        currentDeviceTimeString >= brightness?.timeFrom1! &&
        currentDeviceTimeString <= brightness?.timeFrom2!
      )
        return {
          brightness: brightness?.brightness1,
          schedule: 'DEVICE_BRIGHTNESS_DAY',
        };
      if (
        currentDeviceTimeString < brightness?.timeFrom1! ||
        currentDeviceTimeString > brightness?.timeFrom2!
      )
        return {
          brightness: brightness?.brightness2,
          schedule: 'DEVICE_BRIGHTNESS_NIGHT',
        };
    }
    return { brightness: null, schedule: null };
  }

  getTimezoneOffsetByName(tzName: Maybe<string>) {
    if (!tzName) return '';
    const timeZones = getTimeZones();
    try {
      return timeZones
        .find((x) => x.name === tzName)
        ?.currentTimeFormat.split(' ')[0];
    } catch {
      return '';
    }
  }
}
